import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import Draggable from 'react-draggable';
import GoogleMapReact from 'google-map-react';
import MarkerWithInfoBox from './MarkerWithInfoBox';

const MovableSpluDataOverlay = ({ Key, Alias, ID, Code, Voltage, Current, Energy, PowerFactor, Status, Internet, Permission, LastUpdate, UserName, UserMail, UsageEnergy, closeFunction, readOnlyInput, loc_lat, loc_lng, map_loc, googleMap }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);
  const defaultProps = {
    center: {
      lat: loc_lat,
      lng: loc_lng,
    },
    zoom: 10,
  };
  
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      // Handle map loaded successfully
      console.log('Google Maps API has loaded.');
    } else {
      console.log('Google Maps API not loaded.');
    }
  };
  
  const handleStart = () => {
    setIsDragging(true);
  };

  const handleStop = () => {
    setIsDragging(false);
  };

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  
  const handleMapDrag = (e) => {
    // Prevent propagation of drag events to the parent div
    e.stopPropagation();
  };
  
  const movableOverlayStyle = {
    position: 'fixed',
    top: '35%',
    left: '35%',
    transform: isDragging ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    width: '600px',
    padding: '10px',
    zIndex: 1000,
    cursor: 'move',
    maxHeight: '450px',
    overflowY: 'auto',
  };
  
  const mediaQueryStyle = {
    '@media (min-width: 500px)': {
      maxHeight: '350px',
    },
  };
  
  const overlayHeaderStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  };
  
  const closeButtonStyle = {
    padding: '0',
    margin: '0',
  };
  
  const overlayContentStyle = {
    padding: '10px',
  };
  
  const labelStyle = {
    display: 'block',
  };
  
  const inputStyle = {
    width: '100%',
    marginBottom: '10px',
  };
  
  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  return (
    <Draggable
      onStart={handleStart}
      onStop={handleStop}
      onDrag={handleDrag}
      axis="both"
      defaultPosition={{ x: 0, y: 0 }}
      position={null}
      scale={1}
      zIndex={999}
    >
      <div style={movableOverlayStyle}>
        <div style={overlayHeaderStyle}>
          <Button variant="link" className="close-button" onClick={closeFunction}>
            <BsX color="red" size={24} />
          </Button>
        </div>
        <div style={overlayContentStyle}>
          <label htmlFor="alias">Alias:</label>
          <input id="alias" type={readOnlyInput ? 'text' : 'input'} value={Alias} readOnly={readOnlyInput} />
          <label htmlFor="id">SPLU ID:</label>
          <input id="id" type={readOnlyInput ? 'text' : 'input'} value={ID} readOnly={readOnlyInput} />
          <label htmlFor="code">SPLU Code:</label>
          <input id="code" type={readOnlyInput ? 'text' : 'input'} value={Code} readOnly={readOnlyInput} />
          <label htmlFor="voltage">Voltage:</label>
          <input id="voltage" type={'text'} value={Voltage} readOnly={true} />
          <label htmlFor="current">Current:</label>
          <input id="current" type={'text'} value={Current} readOnly={true} />
          <label htmlFor="energy">Energy:</label>
          <input id="energy" type={'text'} value={Energy} readOnly={true} />
          <label htmlFor="pf">Power Factor:</label>
          <input id="pf" type={'text'} value={PowerFactor} readOnly={true} />
          <label htmlFor="status">Status:</label>
          <input id="status" type={'text'} value={Status == 1 ? 'On Transaction' : 'Idle'} readOnly={true} />
          <label htmlFor="internet">Internet:</label>
          <input id="internet" type={'text'} value={Internet == 1 ? 'Online' : 'Offline'} readOnly={true} />
          <label htmlFor="Permission">Permission:</label>
          <input id="Permission" type={'text'} value={Permission == 1 ? 'Enable' : 'Disable'} readOnly={true} />
          <label htmlFor="lastupdate">Last Update:</label>
          <input id="lastupdate" type={'text'} value={LastUpdate} readOnly={true} />
          <label htmlFor="username" {...(Status === 0 ? { hidden: true } : {})}>Username:</label>
          <input id="username" type={'text'} {...(Status === 0 ? { hidden: true } : {})} value={UserName} readOnly={true} />
          <label htmlFor="usermail" {...(Status === 0 ? { hidden: true } : {})}>Usermail:</label>
          <input id="usermail" type={'text'} {...(Status === 0 ? { hidden: true } : {})} value={UserMail} readOnly={true} />
          <label htmlFor="usageenergy" {...(Status === 0 ? { hidden: true } : {})}>Usage Energy:</label>
          <input id="usageenergy" type={'text'} {...(Status === 0 ? { hidden: true } : {})} value={UsageEnergy} readOnly={true} />
        </div>
        <div style={{ height: '300px', width: '100%', marginLeft: '3px', marginRight: '3px', marginBottom: '3px', marginTop: '10px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDcdYFakFrI0ZqEnz7oOJmy6CHXZ9hXIcg" }}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}>
              <MarkerWithInfoBox
                lat={loc_lat}
                lng={loc_lng}
                text={map_loc}
              />
            </GoogleMapReact>
        </div>
      </div>
    </Draggable>
  );
};

export default MovableSpluDataOverlay;
