// Dashboard.js
// Dashboard.js
// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import PDFExport from '../../components/PDFExport';
import ExcelExport from '../../components/ExcelExport';

function Transaction () {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showPDF, setShowPDF] = useState(false);
  const [tableData, setTableData] = useState([]);
  const renderTableRows = () => {
    return tableData.map((row, index) => (
      <tr key={index}>
        <td>{row.session_id}</td>
        <td>{row.user_name}</td>
        <td>{row.device_name}</td>
        <td>{row.splu_sn.substring(row.splu_sn.length - 3).substring(0, 2)}</td>
        <td>{row.consumed} KWH</td>
        <td>Rp. {row.used}</td>
        <td>{row.timespan}</td>
        <td>
          <button hidden className="btn btn-sm btn-success">
            <i className="bi bi-info-circle"></i> Detail
          </button>
          Not available
        </td>
      </tr>
    ));
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('user-token');
        if (!token) {
          console.error('Token not found in localStorage.');
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        
        const response = await axios.get('https://spluv2.listrikkita.net/api/base-tranasction', {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        if (response.data.code === 200 && response.data.status) {
          setTableData(response.data.data);
          document.querySelector("#count_transcation").textContent = response.data.meta.transaction_count + " Transaction";
          document.querySelector("#count_kwh").textContent = response.data.meta.transaction_kwh + " Kwh";
          document.querySelector("#count_payment").textContent = "Rp. " + response.data.meta.transaction_payment;
        }
        else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        else {
          alert('data not found');
        }
      } catch (error) {
        alert('unexpected exception occured please try again later');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    setTimeout(() => {
      setLoading(false);
    }, 20000); 
  }, []);
  
  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      <div className="container mt-3">
        <div className="row" style={{ textAlign: 'left', color: 'black', marginBottom: '10px', borderBottom: '2px solid black', fontSize: '28px', paddingBottom: '12px', paddingTop: '12px', paddingRight: '12px' }}>
          <h1>Transaction</h1>
        </div>
        <div className="row mt-1 mb-3">
          <div className="col-md-4 col-sm-6">
            <div className="item bg-primary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Total Transaction</p>
              <p className="mb-0" id={ 'count_transcation' }>0 Transaction</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="item bg-success d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Total KWH Consumed</p>
              <p className="mb-0" id={ 'count_kwh' }>0 Kwh</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="item bg-secondary d-flex justify-content-between align-items-center" style={{borderRadius: '20px', color: 'white', border: '1px solid white', padding: '15px', height: '100px', fontSize: '20px'}}>
              <p className="mb-0">Payment Total</p>
              <p className="mb-0" id={ 'count_payment' }>Rp. 0</p>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-button"
              />
              <button className="btn btn-outline-secondary" type="button" id="search-button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-6 text-end">
            <button className="btn btn-primary mx-2">
              <ExcelExport tableData={tableData} />
            </button>
            <button hidden className="btn btn-primary mx-2" onClick={() => setShowPDF(!showPDF)}>Export PDF</button>
          </div>
        </div>
  
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Transactoin ID</th>
              <th scope="col">Customer Name</th>
              <th scope="col">SPLU Name</th>
              <th scope="col">SPLU Slot Number</th>
              <th scope="col">Usage KWH</th>
              <th scope="col">Payment</th>
              <th scope="col">Transaction Timespan</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default Transaction;